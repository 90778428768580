import { Card, CardActions, CardContent, Table, TableCell, TableContainer, TableHead, TableRow, Paper, TableBody, Button } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import React, { useState } from 'react'
import UserTransactions from '../transactions/UserTransactions';

export default function UsersTable({ users }) {

    // Pagination
    const itemsPerPage = 30; // <- Not state but related to below pagination
    const [page, setPage] = useState(1)
    const [noOfPages] = useState(Math.ceil(users.length / itemsPerPage))

    // User Select for Transactions
    const [selectedUser, setSelectedUser] = useState(null)
    const [txOpen, setTxOpen] = useState(false)

    const handleUserSelect = (u) => {
        setSelectedUser(u.id)
        setTxOpen(true)
    }

    return (
        <Card>
            <CardContent>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>First Name</TableCell>
                                <TableCell>Last Name</TableCell>
                                <TableCell>Phone Number</TableCell>
                                <TableCell>ID Type</TableCell>
                                <TableCell>ID Number</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                users && users
                                .slice((page -1) * itemsPerPage, page * itemsPerPage)
                                .map(u => {
                                    return (
                                        <TableRow key={u.id}>
                                            <TableCell>{u.firstName}</TableCell>
                                            <TableCell>{u.lastName}</TableCell>
                                            <TableCell>{u.phoneNumber}</TableCell>
                                            <TableCell>{u.idType}</TableCell>
                                            <TableCell>{u.idNumber}</TableCell>
                                            <TableCell align="right">
                                                <Button onClick={() => handleUserSelect(u)} variant="outlined" color="primary">Transactions</Button>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
            <CardActions>
                <Pagination
                    count={Math.ceil(users.length / itemsPerPage)}
                    page={page}
                    onChange={(e, val) => setPage(val)}
                    defaultPage={1}
                    color="primary"
                    size="large"
                    showFirstButton
                    showLastButton
                /> 
            </CardActions>

            {
                selectedUser
                ?
                <UserTransactions open={txOpen} closeDialog={() => setTxOpen(false)} uid={selectedUser} />
                :
                null
            }
        </Card>
    )
}
