import { Typography } from '@material-ui/core'
import React from 'react'
import { useDb } from '../../contexts/DatabaseContext'
import TransactionsTable from './TransactionsTable'

export default function Transactions() {
    const { GetTransactions } = useDb()
    const transactions = GetTransactions()
    return (
        <div>
            <Typography>Last 500 Transactions</Typography>
            <TransactionsTable transactions={transactions}/>
        </div>
    )
}
