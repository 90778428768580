import { Card, CardContent, Table, TableCell, TableContainer, TableHead, TableRow, Paper, TableBody, CardActions } from '@material-ui/core'
import { Pagination } from '@material-ui/lab';
import React, { useState } from 'react'
import moment from 'moment'
import { useDb } from '../../contexts/DatabaseContext';

export default function TransactionsTable({ transactions }) {

    // Pagination
    const itemsPerPage = 30; // <- Not state but related to below pagination
    const [page, setPage] = useState(1)
    const [noOfPages] = useState(Math.ceil(transactions.length / itemsPerPage))

    const { GetUsers } = useDb();
    const users = GetUsers();

    const getUserName = (uid) => {
        let obj = users && users.find(o => o.id === uid);
        if(obj && obj.firstName) return `${obj.firstName} ${obj.lastName}`
        else return "-"
    }

    return (
        <Card>
            <CardContent>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>User ID</TableCell>
                                <TableCell>User Name</TableCell>
                                <TableCell>Actual Amount (Cents)</TableCell>
                                <TableCell>MSISDN</TableCell>
                                <TableCell>Rand Amount</TableCell>
                                <TableCell>Timestamp</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                users && transactions && transactions
                                .slice((page -1) * itemsPerPage, page * itemsPerPage)
                                .map((tx) => {
                                    return (
                                        <TableRow key={tx.id}>
                                            {/* TODO: Make this uis clickable to a detail view of the user */}
                                            <TableCell>{tx.userId}</TableCell>
                                            <TableCell>{getUserName(tx.userId)}</TableCell>
                                            <TableCell>{tx.amount}</TableCell>
                                            <TableCell>{tx.msisdn}</TableCell>
                                            <TableCell>R {tx.amount / 100}</TableCell>
                                            <TableCell>{moment(tx.timestamp.toDate()).fromNow()}</TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
            <CardActions>
                <Pagination
                    count={Math.ceil(transactions.length / itemsPerPage)}
                    page={page}
                    onChange={(e, val) => setPage(val)}
                    defaultPage={1}
                    color="primary"
                    size="large"
                    showFirstButton
                    showLastButton
                /> 
            </CardActions>
        </Card>
    )
}
