import { Button, Card, CardContent, CircularProgress, Grid, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import BalanceTable from './BalanceTable'
import BalanceUpdate from './BalanceUpdate'
import { useDb } from '../../contexts/DatabaseContext'
import axios from 'axios'
import { useAuth } from '../../contexts/AuthContext'

export default function Balances() {
    const [open, setOpen] = useState(false)
    const { GetUserBalances, getAuthString } = useDb()
    const { confirmYusuf } = useAuth()
    const balances = GetUserBalances()
    const [loading, setLoading] = useState(false)
    const [mainBalance, setMainBalance] = useState(null)
    const [error, setError] = useState(null)

    const handleClose = () => {
        setOpen(false)
    }

    // Action State
    

    const retrieveBalance = async () => {
        setLoading(true)
        const isAdmin = await confirmYusuf()
        let authString = ''
        if(isAdmin) {
            authString = await getAuthString()
            const encodedString = await Buffer.from(authString).toString('base64')
            const finalString = `Basic ${encodedString}`
            var config = {
                headers: { 'Authorization': finalString, 'Content-Type': 'application/json' }
            }
            await axios.post('https://us-central1-afrahindirect.cloudfunctions.net/get_balance', {}, config)
            .then(res => {
                setMainBalance(res.data.balance)
                setLoading(false)

            })
            .catch(err => {
                setLoading(false)
                setError(err)
            })
        }
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={8}>
                <BalanceTable balances={balances}/>
            </Grid>
            <Grid item xs={4}>
                <Card>
                    <CardContent>
                        <Button onClick={() => setOpen(true)} variant={'contained'} color={'primary'}>Credit User Balance</Button><br/>
                        <br/>
                        {
                            loading ?
                            <CircularProgress />
                            :
                            <Button onClick={retrieveBalance} variant="contained">
                                Get Main Balance 
                            </Button>
                        }
                        <br/><br/>
                        {
                            mainBalance
                            ?
                            <Typography>Balance is: <b>R {mainBalance / 100}</b></Typography>
                            :
                            null
                        }
                    </CardContent>
                </Card>

                <BalanceUpdate open={open} closeDialog={handleClose} balanceList={balances} />
                
            </Grid>
        </Grid>
    )
}
