import { AppBar, Tab, Tabs, Toolbar, Box } from '@material-ui/core'
import React, { useState } from 'react'
import logo from '../img/afrahlogo.png'
import PropTypes from 'prop-types'
import Balances from './balances/Balances'
import Transactions from './transactions/Transactions'
import UsersList from './users/UsersList'
import { useAuth } from '../contexts/AuthContext'

function TabPanel(props) {
    const { children, value, index, ...other } = props
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <>{children}</>
          </Box>
        )}
      </div>
    )
}
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}
  
function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
}

export default function Dashboard() {
    const [value, setValue] = useState(0)
    const { logout } = useAuth()

    const handleChange = (event, newValue) => {
        setValue(newValue);
    }

    const handleLogOut = async () => {
      await logout()
    }

    return (
        <>
            <AppBar position={'static'} style={{ background: '#ffcb2d'}}>
                <Toolbar>
                    <div style={{ paddingTop: 10 }}><img src={logo} width="50" alt="Afrah Logo"/></div>
                    <Tabs style={{ float: 'right', color: '#000' }} value={value} onChange={handleChange}>
                        <Tab label="Balances" {...a11yProps(0)} />
                        <Tab label="Transactions" {...a11yProps(1)} />
                        <Tab label="Users" {...a11yProps(2)} />
                        <Tab label="Log Out" onClick={handleLogOut}/>
                    </Tabs>
                </Toolbar>
            </AppBar>
            <TabPanel value={value} index={0}>
              <Balances />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Transactions />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <UsersList />
            </TabPanel>
        </>
    )
}
