import { AppBar, Dialog, DialogActions, DialogContent, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar } from '@material-ui/core'
import React from 'react'
import { useDb } from '../../contexts/DatabaseContext'
import moment from 'moment'
import { Close } from '@material-ui/icons'

export default function UserTransactions({ open, closeDialog, uid }) {
    const { GetUserTransactions } = useDb()
    const txs = GetUserTransactions(uid)

    const handleClose = () => {
        closeDialog()
    }

    return (
        <Dialog open={open} fullScreen onClose={handleClose}>
            <AppBar color="primary">
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <Close />
                        
                    </IconButton>
                    Last 500 Transactions for User
                    
                </Toolbar>
            </AppBar>
            <DialogContent>
                <div style={{ marginTop: 70}} />
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Timestamp</TableCell>
                                <TableCell>Phone Nr.</TableCell>
                                <TableCell>Amount</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                txs && txs.map(t => {
                                    return (
                                        <TableRow key={t.id}>
                                            <TableCell>{moment(t.timestamp.toDate()).format('DD/MM/YYYY')}</TableCell>
                                            <TableCell>{t.phoneNumber}</TableCell>
                                            <TableCell>R {t.randAmount}</TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>

            </DialogActions>
        </Dialog>
    )
}
