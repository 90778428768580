import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

const app = firebase.initializeApp({
    apiKey: "AIzaSyCJBJTjOPnq5Fbrt3ADrmnYQe22_DOd3CU",
    authDomain: "afrahindirect.firebaseapp.com",
    projectId: "afrahindirect",
    storageBucket: "afrahindirect.appspot.com",
    messagingSenderId: "285721717664",
    appId: "1:285721717664:web:9062fe65d8664c3b9112c5"
})

export const auth = app.auth()
export const db = app.firestore()
export default app