import { Dvr } from '@material-ui/icons'
import React, { useContext, useState, useEffect } from 'react'
import { db } from '../firebase'
import { useAuth } from './AuthContext'

const DatabaseContext = React.createContext()

export function useDb() {
    return useContext(DatabaseContext)
}

export default function DatabaseProvider({ children }) {
    const { currentUser } = useAuth()
    

    // GETTING DATA
    function GetUserBalances() {
        const [balances, setBalances] = useState([])
        useEffect(() => {
            const unsubscribe = db
            .collection('userBalances')
            .onSnapshot((snapshot) => {
                const balanceList = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                    label: `${doc.data().phoneNumber} - ${doc.data().firstName} ${doc.data().lastName}`,
                    value: doc.id
                }))
                setBalances(balanceList)
            })
            return unsubscribe
        }, [])
        return balances
    }

    function GetTransactions() {
        const [transactions, setTransactions] = useState([])
        useEffect(() => {
            const unsubscribe = db
            .collection('masterTransactions')
            .orderBy('timestamp', 'desc')
            .limit(500)
            .onSnapshot((snapshot) => {
                const txList = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data()
                }))
                setTransactions(txList)
            })
            return unsubscribe
        }, [])
        return transactions
    }

    function GetUsers() {
        const [users, setUsers] = useState([])
        useEffect(() => {
            const unsubscribe = db
            .collection('users')
            .onSnapshot((snapshot) => {
                const userList = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data()
                }))
                setUsers(userList)
            })
            return unsubscribe
        }, [])
        return users
    }

    async function getAuthString() {
        return await db.collection('authString').get()
        .then(snap => {
            return snap.docs[0].data().string
        })
    }

    function GetUserTransactions(uid) {
        const [txs, setTxs] = useState([])
        useEffect(() => {
            const unsubscribe = db
            .collection('masterTransactions')
            .where('userId', '==', uid)
            .orderBy('timestamp', 'desc')
            .limit(500)
            .onSnapshot((snapshot) => {
                const txList = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data()
                }))
                setTxs(txList)
            })
            return unsubscribe
        }, [uid])
        return txs
    }


    // SETTING DATA
    function addBalance(userId, amount) {
        return db.collection('balanceUpdateRequests')
        .add({
            timestamp: new Date(),
            userId,
            amount,
            createdBy: currentUser.uid
        })
    }

    const value = {
        GetUserBalances,
        addBalance,
        GetTransactions,
        GetUsers,
        getAuthString,
        GetUserTransactions
    }
    
    return (
        <DatabaseContext.Provider value={value}>
            { children }
        </DatabaseContext.Provider>
    )
}
