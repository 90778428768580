import { Button, Dialog, DialogActions, DialogContent, TextField, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import Select from 'react-select'
import { useDb } from '../../contexts/DatabaseContext'

export default function BalanceUpdate({ open, closeDialog, balanceList }) {
    const { addBalance } = useDb()

    const [amount, setAmount] = useState(null)
    const [userId, setUserId] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const handleCancel = () => {
        setAmount(null)
        setUserId(null)
        closeDialog()
    }

    const handleConfirm = async () => {
        setLoading(true)
        try{
            await addBalance(userId, amount).then(() => {
                handleCancel()
                setLoading(false)
            })
        }
        catch(err) {
            setError(err.message)
            setLoading(false)
        }
    }


    return (
        <Dialog maxWidth={'sm'} fullWidth open={open} onClose={closeDialog}>
            <DialogContent>
                <Typography>Credit User</Typography>
                <Select
                    options={balanceList}
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 })}}
                    placeholder={'Select User ...'}
                    onChange={(val) => setUserId(val.id)}
                />
                <br/>
                <TextField 
                    label={'Amount in Cents'}
                    variant={'outlined'}
                    fullWidth
                    onChange={(e) => setAmount(e.target.value)}
                />
                <br/><br/>
                <Typography>Amount to Credit: <b>R {amount / 100}</b></Typography>
                <br/>
                {
                    error ? <span style={{color: 'red'}}>{error}</span> : null
                }
            </DialogContent>
            <DialogActions>
                <Button disabled={loading} color={'secondary'} onClick={handleCancel}>Cancel</Button>
                <Button disabled={loading} onClick={handleConfirm} color={'primary'} variant={'contained'}>Confirm</Button>
            </DialogActions>
        </Dialog>
    )
}
