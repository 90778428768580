import { BrowserRouter, Switch, Route } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import ForgotPassword from "./components/ForgotPassword";
import SignIn from "./components/SignIn";
import { AuthProvider } from "./contexts/AuthContext";
import DatabaseProvider from "./contexts/DatabaseContext";
import PrivateRoute from "./routes/PrivateRoute";

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <DatabaseProvider>
          <BrowserRouter>
            <Switch>
              <PrivateRoute exact path='/' component={Dashboard} />
              <Route path='/signin' component={SignIn} />
              <Route path='/forgot' component={ForgotPassword} />
            </Switch>
          </BrowserRouter>
        </DatabaseProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
