import React from 'react'
import { useDb } from '../../contexts/DatabaseContext'
import UsersTable from './UsersTable'

export default function UsersList() {
    const { GetUsers } = useDb()
    const users = GetUsers()

    return (
        <div>
            <UsersTable users={users} />
        </div>
    )
}
