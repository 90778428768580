import { Button, Card, CardActions, CardContent, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import React, { useState } from 'react'


export default function BalanceTable({ balances }) {
    
    
    // Pagination
    const itemsPerPage = 20; // <- Not state but related to below pagination
    const [page, setPage] = useState(1)
    const [noOfPages] = useState(Math.ceil(balances.length / itemsPerPage))


    return (
        <Card>
            <CardContent>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>User Name</TableCell>
                                <TableCell>User Phone</TableCell>
                                <TableCell>Balance (Cents)</TableCell>
                                <TableCell>Rand Balance</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                balances && balances
                                .slice((page -1) * itemsPerPage, page * itemsPerPage)
                                .map(bal => {
                                    return (
                                        <TableRow key={bal.id}>
                                            <TableCell>{bal.firstName} {bal.lastName}</TableCell>
                                            <TableCell>{bal.phoneNumber}</TableCell>
                                            <TableCell>{bal.balance}</TableCell>
                                            <TableCell>R {bal.balance / 100}</TableCell>
                                           
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
            <CardActions>
                <Pagination
                    count={Math.ceil(balances.length / itemsPerPage)}
                    page={page}
                    onChange={(e, val) => setPage(val)}
                    defaultPage={1}
                    color="primary"
                    size="large"
                    showFirstButton
                    showLastButton
                /> 
            </CardActions>
          
        </Card>
    )
}
